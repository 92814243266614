/** variables */

// slidenav visible class
const openMenuClass = 'slidenav-visible';

// global navigation
const gNav = document.getElementById('js-gnav');
const gnavChildMenuName = '.gnav-child-menu-container';
const gnavChildMenu = gNav.querySelectorAll(gnavChildMenuName);
// current 付与
const gNavTrigger = {
  profile: {
    id: document.getElementById('profileNav'), // 病院案内
    name: [ 'profile' ]
  },
  gairai: {
    id: document.getElementById('gairaiNav'),  // 外来・入院案内
    name: [ 'gairai', 'nyuin' ]
  },
  dept: {
    id: document.getElementById('deptNav'),    // 診療科・各部門
    name: [ 'dept', 'bumon' ]
  },
  kenshin: {
    id: document.getElementById('kenshinNav'), // 健診案内
    name: [ 'kenshin' ]
  },
  medical: {
    id: document.getElementById('medicalNav'), // 医療関係者へ
    name: [ 'medical' ]
  },
  recruit: {
    id: document.getElementById('recruitNav'), // 採用情報
    name: [ 'm-personnel' ]
  }
};
const currentClassName = 'current';

// メディアクエリ
const mediaQuery = window.matchMedia( "(max-width: 979px)" );

// footer mega menu
const footerNav = document.getElementById('footerMegaNav');
const nav = document.querySelector('.footer-mega-menu'),
      smNav = document.getElementById('smFooterMegaNav');

// Click obj
const clickObj = '#js-burger, .burger-icon, #js-burger span, .gnav-trigger';
const mainObj = document.getElementsByTagName('main');




/** initialize */
document.addEventListener('DOMContentLoaded', function(){
  // burger
  burger();
  // pagetop
  pagetop();
  // ページ内リンク
  page_anchor();
  // タップで電話
  telLink();
  // accordion
  accordion();
  // tab
  tab();
  // burger class removed
  mediaQuery.addListener( navClassRemove );
  // footer nav class removed
  mediaQuery.addListener( footerNavClassRemove );

  // メニュー開閉イベント
  mainObj[0].addEventListener('click', function(event){
    const isClass = document.body.classList.contains(openMenuClass);
    if( isClass ){
      document.body.classList.remove(openMenuClass);
      $('#js-gnav').find(gnavChildMenuName).slideUp(300);
    }
  });

  // Global Navigation
  const gnavTrigger = gNav.querySelectorAll('.gnav-heading');

  for (let i = 0; i < gnavTrigger.length; i++){
    gnavTrigger[i].addEventListener('click', function(e){
      if( mediaQuery.matches ){
          //e.preventDefault();
          $(this).parent().siblings().find(gnavChildMenuName).slideUp(300);
          $(this).next(gnavChildMenuName).slideToggle(300);
      }
      return false;
    },
    { passive: false });
  }
  // 子メニューの display: none; を消す
  mediaQuery.addListener(childMenuStyleRemove );

  // footer mega menu
  // クローン
  //const cloneNav = nav.cloneNode(true);
  // 追加
  //smNav.appendChild(cloneNav);
  // child menu 挙動
  const trigger = footerNav.querySelectorAll('.menu-heading');
  for (let i = 0; i < trigger.length; i++){
    trigger[i].addEventListener('click', function(e){
      if( mediaQuery.matches ){
        e.preventDefault();
        // class
        $(this).parent().find('.menu-heading').toggleClass('active');
        $(this).parent().siblings().find('.menu-heading').removeClass('active');
        // slide
        $(this).parent().siblings().find('.child-menu-container').slideUp(300);
        $(this).next('.child-menu-container').slideToggle(300);
      }
      return false;
    },
    { passive: false });
  }

  // global navigation add current

  // リクエストされたパスを取得して配列に変換 → 空の値を削除
  const path = location.pathname.split('/').filter(Boolean);
  // 第一階層のディレクトリ名を取得
  const currentDirName = path[0];
  // 連想配列から検索する
  const isCurrent = Object.keys(gNavTrigger).map( function(key){
    if( gNavTrigger[key].name.indexOf(currentDirName) >= 0 ){
      return gNavTrigger[key].id;
    }
  });
  const current = isCurrent.filter(Boolean);
  // 値が存在するなら該当箇所にクラスをつける
  if( current?.length ){
    Object.keys(gNavTrigger).map( function(key){
      gNavTrigger[key].id.classList.remove(currentClassName);
    });
    current[0].classList.add(currentClassName);
  };

  // IE滅亡
  const ieAlertClose = document.getElementById('ieAlertClose');
  if( ieAlertClose ){
    const ieAlert = document.getElementById('ieAlert');
    ieAlertClose.addEventListener('click', function(e){
      ieAlert.classList.add('invisible');
      console.log(ieAlertClose);
    });
  }

});



// .gnav-child-menu style removed
function childMenuStyleRemove( mq ){
  if( mq.matches ){
    return false;
  } else {
    for (let i = 0; i < gnavChildMenu.length; i++){
      gnavChildMenu[i].removeAttribute('style');
    };
  }
}



/**
***  jQuery .is() -> JavaScript  */
const matches = function( el, selector ) {
  return ( el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector ).call( el, selector );
};



// burger class removed
function navClassRemove( mq ){
  if( mq.matches ){
    return false;
  } else {
    // .slidenav-visible が付与しているかチェック
    const isClass = document.body.classList.contains(openMenuClass);
    if( isClass ){
      document.body.classList.remove(openMenuClass);
      $('#js-gnav').find(gnavChildMenuName).slideUp(300);
    }
  }
}


// footer nav class removed
function footerNavClassRemove( mq ){
  if( mq.matches ){
    return false;
  } else {
    $('#footerMegaNav').find('.menu-heading').removeClass('active');
    $('#footerMegaNav').find('.child-menu-container').removeAttr('style');
  }
}



/**
***  burger  */
const burger = () => {
  const burger = document.getElementById( 'js-burger' );

  if ( burger != null ) {
    burger.addEventListener('click', function () {
      document.body.classList.toggle(openMenuClass);
      $('#js-gnav').find(gnavChildMenuName).slideUp(300);
    });
  }
}



/**
***  pagetop  */
const pagetop = () => {
  const pagetop = document.getElementById('js-pagetop');
  const pagetopActiveClass = 'active';

  if ( pagetop != null ) {
    window.addEventListener('scroll', function (e) {
      if (window.scrollY > 100) {
        pagetop.classList.add(pagetopActiveClass);
      } else {
        pagetop.classList.remove(pagetopActiveClass);
      }
    })
    pagetop.addEventListener('click', function(){
      $('body, html').animate({ scrollTop: 0 }, 400, 'swing');
    });
  }
}



/**
***  accordion  */
const accordion = () => {
  // Cross-browser way to get the computed height of a certain element.
  const getStyle = (el, styleProp) => {
    let value, defaultView = ( el.ownerDocument || document ).defaultView;
    // W3C standard way:
    if ( defaultView && defaultView.getComputedStyle ) {
      // sanitize property name to css notation
      // (hypen separated words eg. font-Size)
      styleProp = styleProp.replace(/([A-Z])/g, "-$1").toLowerCase();
      return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
    } else if ( el.currentStyle ) { // IE
      // sanitize property name to camelCase
      styleProp = styleProp.replace(/\-(\w)/g, function(str, letter) {
        return letter.toUpperCase();
      });
      value = el.currentStyle[styleProp];
      // convert other units to pixels on IE
      if (/^\d+(em|pt|%|ex)?$/i.test(value)) {
        return (function(value) {
          var oldLeft = el.style.left, oldRsLeft = el.runtimeStyle.left;
          el.runtimeStyle.left = el.currentStyle.left;
          el.style.left = value || 0;
          value = el.style.pixelLeft + "px";
          el.style.left = oldLeft;
          el.runtimeStyle.left = oldRsLeft;
          return value;
        })(value);
      }
      return value;
    }
  }
  // アコーディオン処理
  const acc = document.getElementsByClassName('js-accordion-trigger');
  let i;
  // ページを開いた時にアコーディオンを開いた状態にしたいときの処理
  //const firstAccordion = acc[0];
  //const firstPanel = firstAccordion.nextElementSibling;
  //firstAccordion.classList.add('active');
  //firstPanel.style.maxHeight = firstPanel.scrollHeight + 'px';

  // Add onclick listener to every accordion element
  for ( i = 0; i < acc.length; i++ ) {
    acc[i].addEventListener('click', function () {
      // For toggling purposes detect if the clicked section is already 'active'
      const isActive = this.classList.contains('active');
      // Close all accordions
      const allAccordions = document.getElementsByClassName('js-accordion-trigger');
      for ( let j = 0; j < allAccordions.length; j++) {
        // Remove active class from section header
        allAccordions[j].classList.remove('active');
        // Remove the max-height class from the panel to close it
        const panel = allAccordions[j].nextElementSibling;
        const maxHeightValue = getStyle(panel, 'maxHeight');

        if (maxHeightValue !== '0px') {
          panel.style.maxHeight = null;
        }
      }
      // Toggle the clicked section using a ternary operator
      isActive ? this.classList.remove('active') : this.classList.add('active');
      // Toggle the panel element
      const panel = this.nextElementSibling;
      const maxHeightValue = getStyle( panel, 'maxHeight' );
      if (maxHeightValue !== '0px') {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + 'px';
      }
    })
  }
}



/**
***  tab  */
const tab = () => {
  const tabs = document.getElementsByClassName('js-tab')
  const allContents = document.getElementsByClassName('js-contents')
  const currentClassName = 'current'
  const currentViewClassName = 'current-view'
  //
  for (let i = 0; i < tabs.length; i++){
    tabs[i].addEventListener('click', function(){

      // データ属性を取得する
      const view = this.dataset.view;
      // .current が付与しているかチェック
      const isCurrent = this.classList.contains(currentClassName);

      if (!isCurrent) {
        // remove .current
        for ( let j = 0; j < tabs.length; j++ ){
          tabs[j].classList.remove(currentClassName);
        }
        this.classList.add(currentClassName);
      }

      // remove .current-view
      for ( let k = 0; k < allContents.length; k++ ){
        allContents[k].classList.remove(currentViewClassName);
      }
      //
      document.getElementById(view).classList.add(currentViewClassName);
    });
  }
}



/** ページ内リンク */
const page_anchor = function(){
  $('.js-page-anchor').on('click', function(e){

    const href = $(this).attr('href');
    const header = $('header').height();
    let position = $(href).offset().top - header;

    if( href.slice( 0, 1 ) != '#' ){
      e.preventDefault()
    }
    /*
    if( ! window.matchMedia( "(min-width: 813px)" ).matches ){
      position = $(href).offset().top - header
    }
    */

    $('body, html').animate({ scrollTop: position }, 400, 'swing')
  })
}


/** タップで電話 */
/** telLink */
const telLink = function() {
  const ua = navigator.userAgent;
  if( ua.match(/iPhone|Android.+Mobile/) ){
    return;
  } else {
    $('.js-tel-link').each( function() {
      const html = $(this).html();
      const cls  = $(this).attr('class');
      $(this).replaceWith( '<span class="' + cls + '">' + html + '</span>');
    });
  }
};



// ポップアップ処理
const popupModal = () => {
  const elem = document.querySelectorAll( 'a[class^="js_popup"]' );
  const closeBtn = document.getElementById('closeBtn');
  const popupWrapper = document.getElementById('popupWrapper');
  const popupContents = document.getElementById('popupContents');

  elem.forEach(function(item){
    item.addEventListener('click', function(e){
      e.preventDefault();
      const filePath = this.getAttribute('href');

      $.post( '/dept/ajax-popup.php', { file: filePath }, function( data ){

        if( data !== 'false' ){
          popupWrapper.classList.add( 'visible' );
          popupContents.innerHTML = data;
        }
        return;
      });
    });
  });

  closeBtn.addEventListener('click', function(e){
    popupWrapper.classList.remove( 'visible' );
  });

}

// モーダルウインドウ用のマークアップ
const modalMarkup = `
<!-- modal -->
<div class="popup-modal-wrapper" id="popupWrapper">
  <div class="popup-modal-container">
    <div class="popup-close" id="closeBtn"><img src="/images/common/modal-close-icon.svg"></div>
    <div id="popupContents" class="popup-contents"></div>
  </div>
</div>`;



// HTML 内のコメントを削除
const deleteComment = function(){
  const regexp = /<!--[\s\S]*?-->/g;
  document.body.innerHTML = document.body.innerHTML.replace( regexp, '' );
}
